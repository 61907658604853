"use strict";
import {Strophe} from "strophe.js";
import { writeLog,ERROR_INFO } from "../constant";

import "strophejs-plugin-ping";

export class IM {

    /**
     * Xmpp 连接
     */
    client:Strophe.Connection;

    jid:string;

    password:string;

    service_url:string;

    // ping 超时时间
    timeout:number = 300;

    // 连接标记
    isConnect:boolean = false;

    // ping 间隔时间
    timeinterval:number = 15000;

    // ping 的定时器
    pingTimer:number = undefined;

    link_resources:string;

    // 是否重连
    isReconnection:boolean = false;


    user:string;

    constructor(service: string){
        this.service_url = service;
        this.client = new Strophe.Connection(service);
    }

    Connection(jid: string,password:string){

        let res = jid.split('/');
        this.user = res[0];
        // 设置连接资源
        this.link_resources = res[1];

        this.jid = jid;
        this.password = password;
        let ex = this;
        this.client.connect(jid,password,function(status,reason){
            ex.OnConnect(status,reason);
        });
    }
    OnError(res:any){
        console.log(res);
    }
    /**
     * 响应连接
     * @param status
     * @constructor
     */
    OnConnect(status:any,reason:string){
        let Status = Strophe.Status;
        switch (status){
            // 错误
            case Status.ERROR:
                writeLog.writeLogs('连接错误！' + (reason||''));
                this.OnError(ERROR_INFO.XMPP_ERROR);
                break;
            case Status.CONNECTING:
                writeLog.writeLogs('正在建立连接');
                break;
            case Status.CONNFAIL:
                writeLog.writeLogs('连接失败!');
                this.OnError(ERROR_INFO.XMPP_CONNFAIL);
                break;
            case Status.AUTHENTICATING:
                writeLog.writeLogs('连接正在验证!');
                break;
            case Status.AUTHFAIL:
                this.OnError(ERROR_INFO.XMPP_AUTHFAIL);
                writeLog.writeLogs('身份验证失败!');
                break;
            case Status.CONNECTED:
                this.isConnect = true;
                this.OnError(ERROR_INFO.XMPP_CONNECTED);
                writeLog.writeLogs('连接成功!');
                this.OnConnected();
                break;
            case Status.DISCONNECTING:
                this.isConnect = false;
                writeLog.writeLogs('连接正在停止!');
                break;
            case Status.DISCONNECTED:
                this.isConnect = false;
                writeLog.writeLogs('连接中断!');
                this.OnError(ERROR_INFO.XMPP_DISCONNECTED);
                break;
            case Status.ATTACHED:
                writeLog.writeLogs('附加连接');
                break;
            case Status.CONNTIMEOUT:
                writeLog.writeLogs('连接超时！');
                this.OnError(ERROR_INFO.XMPP_CONNTIMEOUT);//todo::去onError的地方判断是否要显示重连按钮？？
                break;
            default:
                break;
        }
    }

    //断开im websocket链接
    //@reason (String) 断开连接的原因，非必填
    public disconnect(reason){
        this.client.disconnect(reason);
    }

    //尝试重连
    public reconnection(){
        let ex = this;
        if(ex.isConnect){
            return;
        }
        writeLog.writeLogs('开始重新连接！');
        //创建ws
        ex.client = new Strophe.Connection(ex.service_url);
        //登录服务
        ex.client.connect(ex.jid,ex.password,function(status,reason){
            ex.OnConnect(status,reason);
        });
    }

    //发送ping
    public sendPing(){
        let ex = this;
        clearInterval(ex.pingTimer);//防止手动重连导致定时器重复
        //初始化定时器
        ex.pingTimer = setInterval(function(){
            if(ex.isConnect == false){
                writeLog.writeLogs('断开连接,ping失败!');
                clearInterval(ex.pingTimer);
                // 断线自动重连
                if(ex.isReconnection){
                    ex.reconnection();
                }
                return false;
            }
            // @ts-ignore
            let pingstatus = ex.client.ping.ping(this.user,function(stanza){
                // @ts-ignore
                ex.client.ping.pong(stanza);
                return true;
            },function(stanza){
                writeLog.writeLogs('ping error: '+ stanza);
            },this.timeout);

        },ex.timeinterval);
    }

    /**
     * 响应事件
     * @constructor
     */
    public OnConnected(){
        let ex = this;

        // ping plus
        ex.sendPing();

        // 添加消息响应
        let status = this.client.addHandler(function(stanza){
            ex.OnMessage(stanza);
            return true;
        },null,"message",null,null,null);

        this.client.send($pres().tree());
    }

    /**
     * 响应消息
     * @param stanza
     * @constructor
     */
    public OnMessage(stanza:Element):any{
        let ex = this;
        // 获取呼叫中心消息推送
        let gnmsg = stanza.getElementsByTagName('GNCustField1');
        if(gnmsg.length > 0){
            let callCentObj = JSON.parse(decodeURIComponent(gnmsg[0].innerHTML));
            ex.OnPreMessage(callCentObj);
            ex.OnUserMessage(callCentObj);
        }
        return stanza;
    }

    public OnUserMessage(callCentObj:any){
        console.log(callCentObj);
    }

    public OnPreMessage(callCentObj:any){
        console.log(callCentObj);
    }
}