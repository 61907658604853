"use strict";
import * as jsonp from 'jsonp'
import { Base64 } from 'js-base64'
import { CONFIG } from "../constant";

export class CallCenter{

    // 认证秘钥
    auth:string;

    // 当前客服账号
    username:string;

    // 当前客服sid
    sid:number;

    constructor(user:string,password:string,sid:number = 0){
        this.username = user;
        this.auth = Base64.encode(user+':'+password);
    }

    /**
     * 呼叫
     */
    public agentDial(phone:number,unique_id,callback:Function){

        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&username='+this.username+'&auth='+this.auth+'&act=agentDial'+'&number='+phone+'&uniqueId='+unique_id,{param:'cp',prefix:'callback'},(err,data) => {
            if (err) {
                console.error(err.message);
            }
            if(typeof(callback) == "function"){
                callback(data,err);
            }
        });
    }

    /**
     * 挂断
     */
    public hangUp(callid:string,callback:Function){
        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&username='+this.username+'&auth='+this.auth+'&act=hangUp'+'&callid='+callid,{param:'cp',prefix:'callback'},(err,data) => {
            if (err) {
                console.error(err.message);
            }
            if(typeof(callback) == "function"){
                callback(data,err);
            }
        });
    }

    /**
     * 获取转接客服/客服组
     */
    public getTransfer(type:string,callback:Function){
        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&username='+this.username+'&auth='+this.auth+'&act='+type,{param:'cp',prefix:'callback'},(err,data) => {
            if (err) {
                console.error(err.message);
            }
            if(typeof(callback) == "function"){
                callback(data,err);
            }
        });
    }

    /**
     * 转接到客服/客服组
     */
    public transferToService(callid:string,transferId:string,mobile:string,callback:Function){
        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&username='+this.username+'&auth='+this.auth+'&act=transferService&callid='+callid+'&transferId='+transferId+'&mobile='+mobile,{param:'cp',prefix:'callback'},(err,data) => {
            let obj;
            if (err) {
                console.error(err.message);
                obj = {
                    err:err,
                    msg:err.message,
                }
            } else {
                obj = data
            }
            if(typeof(callback) == "function"){
                callback(obj,err);
            }
        });
    }

    /**
     * 转接到客服/客服组
     */
    public transferToGroup(callid:string,groupId:string,callback:Function){
        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&username='+this.username+'&auth='+this.auth+'&act=transferGroup&callid='+callid+'&groupId='+groupId,{param:'cp',prefix:'callback'},(err,data) => {
            let obj;
            if (err) {
                console.error(err.message);
                obj = {
                    err:err,
                    msg:err.message,
                }
            } else {
                obj = data
            }
            if(typeof(callback) == "function"){
                callback(obj,err);
            }
        });
    }

}