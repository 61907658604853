"use strict";
import { User } from "./user/main";
import { CallCenter} from "./callcenter/main";
import { IM } from "./im/main";
import { ERROR_INFO,CONFIG,writeLog } from "./constant";
import uuidv4 = require("uuid/v4");

interface iConfig{
    debug ?: boolean //是否开启debug
    host ?: string //配置host
    reconnection ?: boolean //被挤掉或断线是否重连
}

class CC
{
    // 版本号
    static version = 1.004;

    // 客服对象
    static user:User;

    // IM对象
    static im:IM;

    // 呼叫中心对象
    static callcanter:CallCenter;

    // 当前通话id
    static callid:any;

    // 当前通话手机号
    static phone:number;

    // unique_id
    static unique_id:string;

    static config: iConfig = {};

    /**
     * 初始化
     * @param username
     * @param password
     * @param callback xmpp的消息通知
     * @param onerror 登录的错误通知 和 xmpp的错误通知
     */
    public static init(username:string,password:string,callback:any,onerror:any){

        // 设置错误函数
        if(typeof(onerror) == "function"){
            this.onerrorfun = function (data){
                onerror(data);
            };
        }

        let ex = this;

        //配置debug模式
        if(ex.config.debug){
            ex.debug();
        }
        //配置接口host
        if(ex.config.host){
            ex.setHost(ex.config.host);
        }


        writeLog.writeLogs("callback type:" + typeof(callback));
        writeLog.writeLogs("onerror type:" + typeof(onerror));

        // 账号密码错误
        if(username.length < 1){
            this.onerrorfun(ERROR_INFO.LOGIN_ERROR);
        }

        // 用户登录
        ex.user = new User();

        let login_status = ex.user.Login(username,password,function(){
            // callcent api init
            ex.callcanter = new CallCenter(ex.user.username,ex.user.password,ex.user.user_id);

            // 连接推送服务器
            ex.im = new IM(ex.user.message_service);

            //配置断线重连
            ex.setReconnection(ex.config.reconnection);

            // 设置错误监听函数
            ex.im.OnError = function(res){
                ex.onerrorfun(res);
            }

            // 过滤消息函数
            ex.im.OnPreMessage = function(callCentObj){
                ex.onmessage(callCentObj);
            };

            // 设置回掉函数
            if(typeof(callback) == "function"){
                ex.im.OnUserMessage = function (callCentObj){
                    callback(callCentObj);
                };
            }

            // 组装jid
            let jid = 'kf_' + ex.user.username + '@' + ex.user.service_identity;

            // 连接推送服务器
            ex.im.Connection(jid,ex.user.notify_password);

            // 登录成功回掉通知
            ex.onerrorfun(ERROR_INFO.LOGIN_SUCCESS);
        }, function(data){
            if(data.code == '40003'){
                ex.onerrorfun(ERROR_INFO.NO_AUTHORITY);
            }else if(data.code == '40001'){
                ex.onerrorfun(ERROR_INFO.LOGIN_ERROR);
            }else {
                ex.onerrorfun(data);//未知错误
            }
            return false;
        });
        if(login_status){
            writeLog.writeLogs('账号或密码错误!');
        }
        return this;
    }

    /**
     * 响应事件
     * @param callCentObj
     */
    public static onmessage(callCentObj:any){
        writeLog.writeLogs(callCentObj);
        let ex = this;
        if (callCentObj.type == "cc") {
            this.phone = callCentObj.phone;
            if (callCentObj.callId.length > 1 && ex.getCallId() != callCentObj.callId) {
                ex.setCallId(callCentObj.callId);
                writeLog.writeLogs("this callid:" + ex.getCallId());
            }
        }
    }

    /**
     * 响应错误事件
     */
    public static onerrorfun(data:any){
        console.log(data);
    }

    /**
     * 获取当前通话号码
     */
    public static getCallNumber(){
        return this.phone;
    }

    /**
     * 设置callId
     */
    public static setCallId(cid:any){
        return this.callid = cid;
    }
    /**
     * 获取callid
     */
    public static getCallId(){
        return this.callid;
    }

    /**
     * 获取uniqueId
     */
    public static getUniqueID(){
        return this.unique_id;
    }

    /**
     * 呼叫
     * @param phone
     * @param unique_id
     * @param callback
     */
    public static call(phone:number,unique_id:string,callback:Function){
        this.phone = phone;
        this.unique_id = unique_id ? unique_id : uuidv4();
        // 去除 - 呼叫中心业务逻辑不支持 -
        this.unique_id = this.unique_id.replace(/-/g,"");
        this.callcanter.agentDial(phone,this.unique_id,function(data,err){
            writeLog.writeLogs(data);
            if(typeof callback === "function"){
                callback(data,err);
            }
        });
    }

    /**
     * 挂断
     * @param callid
     * @param callback
     */
    public static hangUp(callid:string,callback:Function){
        callid = callid || this.getCallId();
        this.callcanter.hangUp(callid,function(data,err){
            writeLog.writeLogs(data);
            if(typeof callback === "function"){
                callback(data,err);
            }
        });
    }



    /**
     * 开启debug
     */
    public static debug(){
        CONFIG.DEBUG = true;
    }

    /**
     * 重新设置api服务器地址
     */
    public static setHost(host:string){
        let reg = /^(http|https):\/\/.*/;
        if(typeof host === 'string' && reg.test(host)){
            let a = document.createElement('a');
            a.href = host;
            CONFIG.API_HOST = a.origin;
        }

    }

    /**
     * 设置是否自动重连
     */
    public static setReconnection(type:boolean){
        if(!this.im){
            console.warn('You have not initialized. 还没有初始化');
            return false;
        }
        if(typeof type === "boolean"){
            this.im.isReconnection = type;
        }
    }

    /**
     * 手动重连
     */
    public static reconnection(){
        if(!this.im){
            console.warn('You have not initialized. 还没有初始化');
            return false;
        }
        this.im.reconnection();
        return true;
    }

    /*
    * 暂时没用，不要调
    * */
    public static close(){

    }

    /**
     * 断开websocket
     * @reason (String) 断开连接的原因，非必填
     */
    public static disconnect(reason){
        if(!this.im){
            console.warn('You have not initialized. 还没有初始化');
            return false;
        }
        this.im.disconnect(reason);
        return true;
    }

    /**
     * 获取当前连接状态
     * @return true连接中，false连接断开，undefined未初始化
     */
    public static isConnect(){
        return this.im && this.im.isConnect;
    }

    /**
     * 设置配置
     */
    public static setConfig(config: iConfig){
        if(config){
            (<any>Object).assign(this.config, config);
        }
    }

}

window['CC'] = CC;
