/**
 * 日志级别
 */
export class LOGLEV{
    static DEBUG:number = 0;
    static INFO:number = 1;
    static WARN:number = 2;
    static ERROR:number = 3;
}

export class CONFIG{
    static API_HOST:string = 'https://www.bangwo8.com'
    // api服务器地址
    static API_URL:string = '/api/apiCallCenter.php';
    // debug模式
    static DEBUG:boolean = false;
}
export const ERROR_INFO = {
    LOGIN_SUCCESS:{code:0,msg:'登录成功!'},
    LOGIN_ERROR:{code:1001,msg:'账号或密码错误!'},
    NO_AUTHORITY:{code:40003,msg:'没有开通权限'},
    XMPP_CONNECTED:{code:2000,msg:'连接成功'},
    XMPP_ERROR:{code:2001,msg:'消息推送服务器连接错误!'},
    XMPP_CONNFAIL:{code:2002,msg:'消息服务器连接失败'},
    XMPP_AUTHFAIL:{code:2003,msg:'消息服务器认证失败!'},
    XMPP_DISCONNECTED:{code:2004,msg:'断开连接服务器!'},
    XMPP_CONNTIMEOUT:{code:2005,msg:'连接超时'},
}
export class writeLog{

    public static writeLogs(content:string,lev:LOGLEV = LOGLEV.DEBUG){
        if (!CONFIG.DEBUG) {
            return
        }
        if (!window.console
            || !window.console.log
            || !window.console.info
            || !window.console.warn
            || !window.console.error) {
            return
        }
        if(typeof(content) == "object"){
            content = JSON.stringify(content);
        }
        let timeStamp = getTimeStamp();
        content = timeStamp + " :: SDK :: " + content;
        if (lev == LOGLEV.DEBUG) {
            console.log(content)
        }
        else if (lev == LOGLEV.INFO) {
            console.info(content)
        }
        else if (lev == LOGLEV.WARN) {
            console.warn(content)
        }
        else if (lev == LOGLEV.ERROR) {
            console.error(content)
        }

    }
}

/**
 * 获取时间戳
 */
// @ts-ignore
export function getTimeStamp() {
    let now = new Date();
    let timestamp = now.getFullYear()
        + '' + ((now.getMonth() + 1) >= 10 ? (now.getMonth() + 1) : "0" + (now.getMonth() + 1))
        + (now.getDate() >= 10 ? now.getDate() : "0" + now.getDate()) + (now.getHours() >= 10 ? now.getHours() : "0"
            + now.getHours()) + (now.getMinutes() >= 10 ? now.getMinutes() : "0" + now.getMinutes())
        + (now.getSeconds() >= 10 ? now.getSeconds() : "0" + now.getSeconds());
    return timestamp
}
