"use strict";
import * as jsonp from 'jsonp'
import { Base64 } from 'js-base64'
import {CONFIG, ERROR_INFO, writeLog} from "../constant";

export class User{

    // 账号
    username:string;

    // 密码
    password:string;

    // 用户id
    user_id:number;

    // vanderid 代理商id
    vander_id:number;

    // 用户名称
    nickname:number;

    // xmpp服务器地址
    message_service:string;

    // 消息推送账号
    notify_usernname:string;

    // 消息推送密码
    notify_password:string;

    // api url
    api_url:string;

    // 消息服务后缀
    service_identity:string;

    public Login(user:string,pwd:string,callback:Function,errback:Function){
        if(typeof callback !== 'function'){
            callback = function (data){
                writeLog.writeLogs('Login callback:',data);
            }
        }
        if(typeof errback !== 'function'){
            errback = function (data){
                writeLog.writeLogs('Login errback:',data);
            }
        }

        if(user.length < 1 || pwd.length < 1){
            errback(ERROR_INFO.LOGIN_ERROR);//用户名/密码错误
            return false;
        }

        this.username = user;
        this.password = pwd;

        let auth = Base64.encode(user + ':' + pwd);

        jsonp(CONFIG.API_HOST +CONFIG.API_URL + '?server=' + CONFIG.API_HOST + '&auth='+auth+'&version=2.0',{timeout:0,param:'cp',prefix:'callback'},(err,data) => {
            if (err) {
                // Error
                errback(err);
                console.error(err.message);
            } else {
                if(data.code == 0){
                    // Success
                    let userinfo = data.data;
                    this.user_id = userinfo.sId;
                    this.vander_id = userinfo.aId;
                    this.nickname = userinfo.realName;
                    this.service_identity = userinfo.service_identity;
                    this.notify_usernname = userinfo.notify_username;
                    this.notify_password = userinfo.notify_password;
                    this.message_service = userinfo.message_service;
                    this.api_url = userinfo.api_url;
                    callback();
                    return this;
                }else{
                    // Error
                    errback(data);
                }
            }
        });
    }

}